import React, { useEffect, useState } from 'react';

export default function Cubes() {
    const [temperature, setTemperature] = useState(null);
    const [humidity, setHumidity] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://esp-api-g2bk.onrender.com/reading');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                setTemperature(data.temperature);
                setHumidity(data.humidity);
                setLastUpdated(data.createdAt);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 30000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="bg-card p-8 rounded-lg shadow-lg">
            {error ? (
                <div className="text-red-500">An error occured: {error}</div>
            ) : (
                <div className="grid gap-4">
                    <div>
                        <h2 className="text-2xl font-bold">Current Readings</h2>
                    </div>
                    <div className="flex items-center justify-center">
                        <span className="text-8xl font-bold text-primary">
                            {temperature !== null ? `${temperature}°` : '...'}
                        </span>
                        <span className="text-4xl font-bold text-secondary">C</span>
                    </div>
                    <div className="flex items-center justify-center">
                        <span className="text-4xl font-bold text-secondary-foreground">
                            {humidity !== null ? `${humidity}%` : '...'}
                        </span>
                        <span className="text-2xl font-bold text-secondary">Humidity</span>
                    </div>
                    <div className="flex items-center justify-center text-muted-foreground text-sm">
                        Last updated at {lastUpdated ? new Date(lastUpdated).toLocaleTimeString() : '...'}
                    </div>
                </div>
            )}
        </div>
    );
}