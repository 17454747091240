import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

export default function Graph() {

    const [data, setData] = useState({ labels: [], temperatures: [], humidities: [] });
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://esp-api-g2bk.onrender.com/readingsToday');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                // Extract temperature and humidity data
                const labels = data.map(item => new Date(item.createdAt).toLocaleTimeString());
                const temperatures = data.map(item => item.temperature);
                const humidities = data.map(item => item.humidity);

                setData({ labels, temperatures, humidities });
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 30000); 
        return () => clearInterval(intervalId);
    }, []);

    const chartData = {
        labels: data.labels,
        datasets: [
            {
                label: 'Temperature (°C)',
                data: data.temperatures,
                fill: false,
                borderColor: 'hsl(220, 90%, 60%)',
                tension: 0.1
            },
            {
                label: 'Humidity (%)',
                data: data.humidities,
                fill: false,
                borderColor: 'hsl(210, 10%, 90%)',
                tension: 0.1
            }
        ]
    };

    const options = {
        scales: {
            x: {
                display: false, 
            },
            y: {
                beginAtZero: false,
                ticks: {
                    display: false 
                },
                grid: {
                    display: false 
                }
            }
        }
    };

    return (
        <div className='bg-card p-8 rounded-lg shadow-lg'>
            {error ? (
                <p>Error: {error}</p>
            ) : (
                <>
                    <h2 className="text-2xl font-bold">Today's Graph</h2>
                        <Line data={chartData} options={options}/>
                </>
            )}
        </div>
    );
}

