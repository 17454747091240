import React, { useEffect, useState } from 'react';

export default function Cubes() {
    const [averageTemperature, setAverageTemperature] = useState(null);
    const [averageHumidity, setAverageHumidity] = useState(null);
    const [firstRecordDate, setFirstRecordDate] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://esp-api-g2bk.onrender.com/readings');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                if (data.length > 0) {
                    // Calculate totals
                    const totalTemperature = data.reduce((acc, reading) => acc + reading.temperature, 0);
                    const totalHumidity = data.reduce((acc, reading) => acc + reading.humidity, 0);

                    // Calculate averages
                    const averageTemperature = totalTemperature / data.length;
                    const averageHumidity = totalHumidity / data.length;

                    setAverageTemperature(averageTemperature);
                    setAverageHumidity(averageHumidity);

                    // Find the earliest record date
                    const dates = data.map(reading => new Date(reading.createdAt));
                    const earliestDate = new Date(Math.min(...dates));
                    setFirstRecordDate(earliestDate.toISOString());
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 30000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="bg-card p-8 rounded-lg shadow-lg">
            {error ? (
                <div className="text-red-500">An error occurred: {error}</div>
            ) : (
                <div className="grid gap-4">
                    <div>
                        <h2 className="text-2xl font-bold">All-time Averages</h2>
                    </div>
                    <div className="flex items-center justify-center">
                        <span className="text-8xl font-bold text-primary">
                            {averageTemperature !== null ? `${averageTemperature.toFixed(1)}°` : '...'}
                        </span>
                        <span className="text-4xl font-bold text-secondary">C</span>
                    </div>
                    <div className="flex items-center justify-center">
                        <span className="text-4xl font-bold text-secondary-foreground">
                            {averageHumidity !== null ? `${averageHumidity.toFixed(1)}%` : '...'}
                        </span>
                        <span className="text-2xl font-bold text-secondary">Humidity</span>
                    </div>
                    <div className="flex items-center justify-center text-muted-foreground text-sm">
                        First record from {firstRecordDate ? new Date(firstRecordDate).toLocaleDateString() : '...'}
                    </div>
                </div>
            )}
        </div>
    );
}